import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div>
          <h1>Your QnA</h1>
          <p>
            COMING VERY SOON!
          </p>
        </div>
      </header>
    </div>
  );
}

export default App;
